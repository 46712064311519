import React, {useEffect, useState} from 'react';
import editPhone from '../../utils/editPhone';
import FormValidators from '../../utilities/form-validators';
import Form from '../../components/form';
import FormField from '../../components/form-field';
import LookUpFormHeader from './Components/LookUpFormHeader';
import {useDispatch} from "react-redux";
import {updateContactNumber} from "../../action";
import _uniqueId from 'lodash/uniqueId';
import { useLocale } from '../../contexts/LocaleContext';
import { TRANSLATION_CONSTANTS } from '../../app-consts/translations';
import { Button } from 'mesh-component-library';

const PhoneNumberLookup = ({partnerDetails, dispatchCommonBenefitsLookup, showModal, closeModal, currentLookup = ""}) => {
  const {displayCode} = partnerDetails;
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [id] = useState(() => _uniqueId());
  const [lastName, setLastName] = useState("");
  const getTranslatedText = useLocale();
  
  const isLastNameRequired = currentLookup === 'phoneNumberLastNameLookup';

  const continueClick = () => {
    dispatch(updateContactNumber(phoneNumber));
    const user = {};
    user.phoneLookup = true;
    if(isLastNameRequired) { 
      user.lastName = lastName;
    }
    user.memberId = phoneNumber.replace(/-/g, '');
    user.lookupType = 'phoneNumber'
    dispatchCommonBenefitsLookup(user);

    // TODO: these localstorage props look like they are used on the location screen, but need to confirm if that use will be going away
    localStorage.setItem('memberId', user.memberId);
  }

  const phoneOnChange = (event) => {
    const editedPhoneNumber = editPhone(event.target.value);
    setPhoneNumber(editedPhoneNumber);
    return phoneNumber
  }

  const onSubmit = () => {

    const content = <div>
      <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.PHONE_NUMBER)}:</span> {phoneNumber}</p>
      {isLastNameRequired && <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.LAST_NAME)}:</span> {lastName}</p>}
    </div>

    const options = {
      titleHtml: content,
      yesNoSelected,
    }

    showModal(options);
  }
  const yesNoSelected = async ({target: {value}}) => {
    closeModal()
    if (value === "yes") {
      continueClick()
    }
  }

  useEffect(() => {
    setIsValidPhone(/\d{3}-\d{3}-\d{4}/.test(phoneNumber));
  }, [phoneNumber])

  useEffect(() => {
    if (isLastNameRequired) {
      const valid = /\d{3}-\d{3}-\d{4}/.test(phoneNumber) && lastName.length >= 1;
      setIsValidPhone(valid);
    }
  }, [phoneNumber, lastName])


  return (
    <React.Fragment>
      <LookUpFormHeader titleText={getTranslatedText(TRANSLATION_CONSTANTS.NEED_INFO_BENEFITS)} id={`we-need-some-info-to-find-your-benefits-phone-number-${id}`}/>
      <Form id={`phoneNumberLookupForm-${id}`}
            className="c-container-lookup__form"
            validateOnBlur={true}>
        <FormField
          id={`phoneNumber-${id}`}
          errorText={getTranslatedText(TRANSLATION_CONSTANTS.ENTER_PHONE_NUMBER)}
          name="phoneNumber"
          label={getTranslatedText(TRANSLATION_CONSTANTS.PHONE_NUMBER)}
          type="tel"
          meta="(Primary Member)"
          onChange={phoneOnChange}
          value={phoneNumber}
          validators={[FormValidators.phoneNumberValidator]}
          x-autocompletetype="phone-full"
          autoComplete="tel"
          required
        />
        { isLastNameRequired &&
          <FormField
            errorText={getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_LAST_NAME)}
            label={getTranslatedText(TRANSLATION_CONSTANTS.LAST_NAME)}
            id="lastName"
            className="lastName"
            minLength={1}
            name="lastName"
            autoComplete="lastName"
            value={lastName}
            onChange={(event) => setLastName(event.target.value.trim())}
            required />
        }
        <Button
          size='lg'
          hasUpgrade
          id={`phone-lookup-continue-${id}`}
          disabled={!isValidPhone}
          onClick={onSubmit}
          utils={{
            fullWidth: true
          }}>
          {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
        </Button>
      </Form>
    </React.Fragment>
  )
}

export default PhoneNumberLookup;
